class ParseServices {
  parseMainData() {
    let mainData = [];
    document.forms[0].mainData.forEach((item) => {
      let tempObj = {};
      item.elements.forEach((childrenItem) => {
        if (childrenItem.checked && childrenItem.type === "radio") {
          tempObj["disabled"] = childrenItem.value === "true";
        } else if (childrenItem.type !== "radio") {
          tempObj[childrenItem.name] = childrenItem.value;
        }
      });
      mainData.push(tempObj);
    });
    return mainData;
  }

  parseBrokerLicenses() {
    this.dataToSend = document.querySelector(".main_data").elements;
    let brokerLicenses = [];
    let tempObj = {};
    document.forms[0].licensesSelect.elements.forEach((item) => {
      if (item.type === "text" && item.value !== "" && item.value !== null) {
        if (item.parentElement.style.display === "none") return;
        tempObj["licenseNumber"] = item.value;
        tempObj["licenseId"] = item.getAttribute("data-licenseId")
          ? item.getAttribute("data-licenseId")
          : item.getAttribute("data-id");
        tempObj["id"] = item.getAttribute("data-id");
        brokerLicenses.push(tempObj);
        tempObj = {};
      }
    });
    return brokerLicenses;
  }

  parseVipConditions() {
    let vipConditions = [];
    document.forms[0].vipCondition.forEach((item) => {
      let tempObj = {};
      item.elements.forEach((childrenItem) => {
        if (childrenItem.type !== "radio" && childrenItem.type !== "button") {
          tempObj[childrenItem.name] = childrenItem.value;
        }
      });
      vipConditions.push(tempObj);
    });
    return vipConditions;
  }

  parseBrokerOptions() {
    let brokerOptions = [];

    document.forms[0].brokerOptions.elements.forEach((item) => {
      if (item.checked && item.type === "radio") {
        brokerOptions.push({
          id: item.id || null,
          optionId: parseInt(item.getAttribute("data-optionid")),
          state: item.value,
        });
      }
    });
    return brokerOptions;
  }

  parseVipOptions() {
    let vipOptions = [];

    document.forms[0].vipOptions.elements.forEach((item) => {
      if (item.checked && item.type === "radio") {
        vipOptions.push({
          id: item.id || null,
          optionId: parseInt(item.getAttribute("data-optionid")),
          state: item.value,
        });
      }
    });

    return vipOptions;
  }

  parseSimpleInputs() {
    let allInputs = [];
    document.forms[0].forEach((input) => {
      let obj = {};
      if (
        input.tagName.toLowerCase() !== "button" &&
        input.tagName.toLowerCase() !== "fieldset"
      ) {
        obj.propName = input.name;
        obj.data =
          input.type === "number" && !isNaN(parseFloat(input.value))
            ? parseFloat(input.value)
            : input.type === "number" && isNaN(parseFloat(input.value))
            ? null
            : input.type === "radio" && !input.classList.contains("no-parse")
            ? Array.from(
                input
                  .closest(".radio__container")
                  .querySelectorAll('input[type="radio"]')
              ).find((radio) => radio.checked)?.value
            : input.type !== "radio"
            ? input.value
            : "";
      }

      allInputs.push(obj);
    });
    return allInputs;
  }

  parseBrokerHistory() {
    let brokerHistory = [];
    document.forms[0].history.forEach((item) => {
      Array.from(item.elements)
        .filter((element) => element.name === "historyItem")
        .forEach((historyItem) => {
          if (historyItem.elements.text.value !== "") {
            brokerHistory.push({
              text: historyItem.elements.text.value,
              date: historyItem.elements.date.value,
              id: historyItem.id,
              lang: historyItem.closest(".translation_info__content").elements
                .languageSlug.value,
            });
          }
        });
    });
    return brokerHistory;
  }

  parseDemoPlatforms() {
    let demoPlatforms = [];
    Array.from(
      document.forms[0].querySelectorAll("fieldset[name=demoPlatform]")
    ).forEach((platform) => {
      if (platform.elements.selectedPlatform.value !== "") {
        demoPlatforms.push({
          id: platform.id,
          demoTradingPlatformId: platform.elements.selectedPlatform.value,
          demoTradingPlatformCondition:
            platform.elements.selectedCondition.value,
          demoTradingPlatformConditionDays:
            platform.elements.demoTradingPlatformConditionDays.value,
        });
      }
    });
    return demoPlatforms;
  }

  parseScalpingExplanations() {
    let scalpingExplanations = [];
    if (document.forms[0].scalpingLimits) {
      Array.from(document.forms[0].scalpingLimits).forEach((item) => {
        scalpingExplanations.push({
          languageSlug: item.elements.languageSlug.value,
          description: item.elements.description.value,
          id: item.elements.id.value,
        });
      });
    }

    return scalpingExplanations;
  }

  parseEaExplanations() {
    let eaExplanations = [];
    if (document.forms[0].eaLimits) {
      Array.from(document.forms[0].eaLimits).forEach((item) => {
        eaExplanations.push({
          languageSlug: item.elements.languageSlug.value,
          description: item.elements.description.value,
          id: item.elements.id.value,
        });
      });
    }
    return eaExplanations;
  }

  parseSpecialConditions() {
    let specialConditions = [];
    if (document.forms[0].specialConditions) {
      Array.from(document.forms[0].specialConditions).forEach((item) => {
        specialConditions.push({
          languageSlug: item.elements.languageSlug.value,
          description: item.elements.description.value,
          id: item.elements.id.value,
        });
      });
    }
    return specialConditions;
  }

  // parseInstruments() {
  //   let instruments = [];
  //
  //   if (document.forms[0].checkboxItem) {
  //     Array.from(document.forms[0].checkboxItem).forEach((item) => {
  //       let tempObj = {};
  //       let checked = Array.from(item.elements).find(
  //         (input) => input.type === "checkbox" && input.checked
  //       );
  //       if (checked) {
  //         tempObj.typeLeverage = this.findRadioButtonValue(
  //           item.elements,
  //           "leverageType"
  //         );
  //         tempObj.kindLeverage = this.findRadioButtonValue(
  //           item.elements,
  //           "kindLeverage"
  //         );
  //         tempObj.lowestLeverage =
  //           tempObj.kindLeverage === "VARIABLE"
  //             ? parseFloat(item.elements.lowestLeverage.value)
  //             : null;
  //         tempObj.maxLeverage = parseFloat(item.elements?.maxLeverage?.value);
  //
  //         tempObj.typeSpread = this.findRadioButtonValue(
  //           item.elements,
  //           "typeSpread"
  //         );
  //
  //         tempObj.kindSpread = this.findRadioButtonValue(
  //           item.elements,
  //           "kindSpread"
  //         );
  //         tempObj.lowestSpread =
  //           tempObj.kindSpread === "VARIABLE"
  //             ? parseFloat(item.elements.instrumentLowestSpread.value)
  //             : null;
  //         tempObj.typicalSpread = parseFloat(
  //           item.elements.instrumentTypicalSpread.value
  //         );
  //
  //         tempObj.instrumentName = item.name;
  //         tempObj.id = item.getAttribute("data-id");
  //         tempObj.instrumentId = item.getAttribute("data-licenseId")
  //           ? item.getAttribute("data-licenseId")
  //           : item.getAttribute("data-id");
  //         instruments.push(tempObj);
  //       }
  //     });
  //   }
  //   return instruments;
  // }

  parseReviewedField() {
    let dataToReview = [];
    document
      .querySelector(".form__content")
      .querySelectorAll(".checker")
      .forEach((checker) => {
        let reviewedFieldState = Array.from(
          checker.querySelector(".checker_set").elements
        ).find((input) => input.checked).value;
        let changesDataExist = checker.parentNode.querySelector(
          '*[data-type="changesValue"]'
        );
        if (
          checker.parentNode.querySelector('*[data-type="currentValue"]') ||
          checker.closest(".translation_info__content") ||
          checker.closest(".demo_platform__container")
        ) {
          let objToReview = {};
          objToReview.type = this.getReviewedType(checker);
          objToReview.name = this.getReviewedName(checker);
          objToReview.languageSlug = this.getReviwedLanguage(checker);
          objToReview.value =
            reviewedFieldState === "true" &&
            this.getReviewedValueField(checker, "changesValue")
              ? this.getReviewedValueField(checker, "changesValue")
              : this.getReviewedValueField(checker, "currentValue");
          objToReview.id = this.getReviewedId(checker);
          objToReview.optionId = this.getReviewedOptionId(checker);
          objToReview.licenseId = checker.closest(".checkbox_select")
            ? parseInt(
                checker.parentNode
                  .querySelector('*[data-type="currentValue"]')
                  .getAttribute("data-licenseId")
              )
            : null;
          objToReview.date = this.getReviewedDate(
            checker,
            reviewedFieldState,
            changesDataExist
          );
          objToReview.text = this.getReviewedText(
            checker,
            reviewedFieldState,
            changesDataExist
          );
          objToReview.demoTradingPlatformId = this.getReviewedTradingPlatformId(
            checker,
            reviewedFieldState,
            changesDataExist
          );
          objToReview.demoTradingPlatformConditionDays =
            this.getReviewedTradingPlatformConditionDays(
              checker,
              reviewedFieldState,
              changesDataExist
            );
          objToReview.demoTradingPlatformCondition =
            this.getReviewedDemoTradingPlatformCondition(
              checker,
              reviewedFieldState,
              changesDataExist
            );
          objToReview.ticker = this.getReviewedTicker(
            checker,
            reviewedFieldState
          );
          objToReview.linkTicker = this.getReviewedLinkTicker(
            checker,
            reviewedFieldState
          );

          dataToReview.push(objToReview);
        }
      });

    return dataToReview;
  }

  parseRollbackField() {
    let dataToRollback = [];
    document
      .querySelector(".form__content")
      .querySelectorAll(".checker")
      .forEach((checker) => {
        let rollbackFieldState = Array.from(
          checker.querySelector(".checker_set").elements
        ).find((input) => input.checked).value;

        if (
          (checker.parentNode.querySelector('*[data-type="currentValue"]') ||
            checker.closest(".translation_info__content") ||
            checker.closest(".demo_platform__container")) &&
          rollbackFieldState === "unset"
        ) {
          dataToRollback.push({
            type: this.getRollbackType(checker),
            languageSlug: checker?.closest(".translation_info__content")
              ?.elements?.languageSlug?.value,
            name: this.getRollbackName(checker),
            value: this.getRollbackValue(checker),
            id: this.getRollbackId(checker),
            optionId: this.getRollbackOptionId(checker),
            licenseId: this.getRollbackLicenseId(checker),
            date: this.getRollbackDate(checker),
            text: this.getRollbackText(checker),
            demoTradingPlatformId:
              this.getRollbackDemoTradingPlatformId(checker),
            demoTradingPlatformConditionDays:
              this.getRollbackDemoTradingPlatformConditionDays(checker),
            demoTradingPlatformCondition:
              this.getRollbackDemoTradingPlatformCondition(checker),
            ticker: this.getRollbackTicker(checker),
            linkTicker: this.getRollbackLinkTicker(checker),
          });
        }
      });
    return dataToRollback;
  }

  parseReviewedAccountTypes() {
    let dataToReview = [];
    document
      .querySelector(".form__content")
      .querySelectorAll(".checker")
      .forEach((checker) => {
        let reviewedFieldState = Array.from(
          checker.querySelector(".checker_set").elements
        ).find((input) => input.checked).value;
        if (
          checker.parentNode.querySelector('*[data-type="currentValue"]') ||
          checker.closest(".translation_info__content") ||
          checker.closest(".demo_platform__container")
        ) {
          let obj = {};

          obj.type = this.getReviewedType(checker);
          obj.name = this.getReviewedName(checker);
          obj.languageSlug = this.getReviwedLanguage(checker);
          obj.value =
            reviewedFieldState === "true" &&
            this.getReviewedValueField(checker, "changesValue")
              ? this.getReviewedValueField(checker, "changesValue")
              : this.getReviewedValueField(checker, "currentValue");
          obj.id = this.getReviewedId(checker);
          if (obj.type === "licenseNumber") {
            obj = {
              ...this.getReviewedInstrument(checker, reviewedFieldState),
              ...obj,
            };
          }
          if (obj.type === "currencyPair") {
            obj = {
              ...obj,
              ...this.getReviewedCurrencyPair(checker, reviewedFieldState),
            };
          }
          dataToReview.push(obj);
        }
      });
    return dataToReview;
  }

  parseRollbackAccountTypes() {
    let dataToRollback = [];
    document
      .querySelector(".form__content")
      .querySelectorAll(".checker")
      .forEach((checker) => {
        let rollbackFieldState = Array.from(
          checker.querySelector(".checker_set").elements
        ).find((input) => input.checked).value;

        if (
          (checker.parentNode.querySelector('*[data-type="currentValue"]') ||
            checker.closest(".translation_info__content") ||
            checker.closest(".demo_platform__container")) &&
          rollbackFieldState === "unset"
        ) {
          let obj = {};
          obj.type = this.getRollbackType(checker);
          obj.languageSlug = checker?.closest(
            ".translation_info__content"
          )?.elements?.languageSlug?.value;
          obj.name = this.getRollbackName(checker);
          obj.value = this.getRollbackValue(checker);
          obj.id = this.getRollbackId(checker);
          if (obj.type === "licenseNumber") {
            obj = {
              ...this.getRollbackInstruments(checker),
              ...obj,
            };
          }
          if (obj.type === "currencyPair") {
            obj = {
              ...obj,
              ...this.getRollbackCurrencyPair(checker),
            };
          }

          dataToRollback.push(obj);
        }
      });
    return dataToRollback;
  }

  parseBrokerNotes() {
    let parseBrokerNotes = [];
    if (document.forms[0].brokerNotes) {
      Array.from(document.forms[0].brokerNotes).forEach((item) => {
        parseBrokerNotes.push({
          languageSlug: item.elements.languageSlug.value,
          description: item.elements.description.value,
          id: item.elements.id.value,
        });
      });
    }
    return parseBrokerNotes;
  }

  brokerWarningSings() {
    let parsebrokerWarningSings = [];
    if (document.forms[0].brokerWarningSings) {
      Array.from(document.forms[0].brokerWarningSings).forEach((item) => {
        parsebrokerWarningSings.push({
          languageSlug: item.elements.languageSlug.value,
          description: item.elements.description.value,
          id: item.elements.id.value,
        });
      });
    }
    return parsebrokerWarningSings;
  }

  parseBrokerCompanies() {
    let brokerCompanies = [];
    Array.from(document.forms[0].company_item).forEach((item) => {
      if (item.elements.name && item.elements.name.value !== "") {
        brokerCompanies.push({
          name: item.elements.name.value,
          ticker: item.elements.ticker.value,
          linkTicker: item.elements.linkTicker.value,
          id: item.elements.id.value === "" ? null : item.elements.id.value,
          languageSlug: item.elements.languageSlug.value,
        });
      }
    });

    return brokerCompanies;
  }

  findRadioButtonValue(inputs, name) {
    return Array.from(inputs)
      .find((input) => input.name.includes(name) && input.checked)
      ?.value?.toUpperCase();
  }

  getReviewedValueField(target, type) {
    if (target.parentNode.querySelector(`*[data-type="${type}"]`)) {
      return (
        target.parentNode.querySelector(`*[data-type="${type}"]`).value ||
        target.parentNode
          .querySelector(`*[data-type="${type}"]`)
          .getAttribute("value")
      );
    }
  }

  getReviwedLanguage(target) {
    if (target.closest(".translation_info__content")) {
      return target.closest(".translation_info__content").elements.languageSlug
        .value;
    }
  }

  getReviewedType(target) {
    let parent =
      target.closest(".translation_info__content") ||
      target.closest(".checkbox_select") ||
      target.closest(".demo_platform__container") ||
      target.closest(".options_wrap") ||
      target.closest(".vipOptions_wrap") ||
      target.closest(".currency_pairs_wrap");

    if (parent) {
      switch (parent) {
        case target.closest(".translation_info__content"):
          // case target.closest(".checkbox_select"):
          return parent.name;
        case target.closest(".demo_platform__container"):
          return "demoPlatform";
        case target.closest(".vipOptions_wrap"):
          return "brokerVipOption";
        case target.closest(".options_wrap"):
          return "brokerOption";
        case target.closest(".currency_pairs_wrap"):
          return "currencyPair";
      }
    } else {
      return null;
    }
  }

  getReviewedName(target) {
    if (target.parentNode.querySelector('*[data-type="currentValue"]')) {
      if (
        target?.closest(".translation_info__content")?.name !== "companyBlock"
      ) {
        return target.parentNode.querySelector('*[data-type="currentValue"]')
          .name;
      } else {
        const current = target.parentNode
          .querySelector('*[data-type="currentValue"]')
          ?.querySelector('[name="name"]')?.value;
        const changed = target.parentNode
          .querySelector('*[data-type="changesValue"]')
          ?.querySelector('[name="name"]')?.value;

        if (current?.length > 0 && changed?.length === 0) {
          return changed;
        } else {
          return current || changed;
        }
      }
    }
  }

  getReviewedId(target) {
    if (target.closest(".options_wrap") || target.closest(".vipOptions_wrap")) {
      return parseInt(
        target.parentNode.querySelector('*[data-type="currentValue"]').id
      );
    } else if (target.closest(".checkbox_select")) {
      return parseInt(
        target.parentNode
          .querySelector('*[data-type="currentValue"]')
          .getAttribute("data-id")
      );
    } else if (
      (target.closest(".translation_info__content") &&
        (target.closest(".translation_info__content").name === "history" ||
          target.closest(".translation_info__content").name ===
            "companyBlock")) ||
      target.closest(".demo_platform__container")
    ) {
      return target.parentNode.querySelector('*[data-type="currentValue"]').id;
    } else {
      return target.parentNode.querySelector('*[data-type="currentValue"]').id
        ? target.parentNode.querySelector('*[data-type="currentValue"]').id
        : null;
    }
  }

  getReviewedOptionId(target) {
    if (target.closest(".options_wrap") || target.closest(".vipOptions_wrap")) {
      return parseInt(
        target.parentNode.querySelector('*[data-type="currentValue"]').id
      );
    } else if (
      target.closest(".translation_info__content")?.name === "history" ||
      target.closest(".demo_platform__container")
    ) {
      return target.parentNode.querySelector('*[data-type="currentValue"]').id;
    } else return null;
  }

  getReviewedDate(target, reviewedFieldState, changesDataExist) {
    if (reviewedFieldState === "false") {
      if (changesDataExist) {
        if (
          target.parentNode
            .querySelector('*[data-type="currentValue"]')
            .querySelector('input[name="date"]') !== null
        ) {
          return target.parentNode
            .querySelector('*[data-type="currentValue"]')
            .querySelector('input[name="date"]').value;
        }
      }
    } else if (reviewedFieldState === "true") {
      if (
        target.parentNode
          .querySelector('*[data-type="currentValue"]')
          .querySelector('input[name="date"]') !== null
      ) {
        return changesDataExist
          ? target.parentNode
              .querySelector('*[data-type="changesValue"]')
              .querySelector('input[name="date"]').value
          : target.parentNode
              .querySelector('*[data-type="currentValue"]')
              .querySelector('input[name="date"]').value;
      }
    }
  }

  getReviewedText(target, reviewedFieldState, changesDataExist) {
    if (reviewedFieldState === "false") {
      if (changesDataExist) {
        if (
          target.parentNode
            .querySelector('*[data-type="currentValue"]')
            .querySelector('input[name="text"]') !== null
        ) {
          return target.parentNode
            .querySelector('*[data-type="currentValue"]')
            .querySelector('input[name="text"]').value;
        }
      }
    } else {
      if (
        target.parentNode
          .querySelector('*[data-type="currentValue"]')
          .querySelector('input[name="text"]') !== null
      ) {
        return changesDataExist
          ? target.parentNode
              .querySelector('*[data-type="changesValue"]')
              .querySelector('input[name="text"]').value
          : target.parentNode
              .querySelector('*[data-type="currentValue"]')
              .querySelector('input[name="text"]').value;
      }
    }
  }

  getReviewedTradingPlatformId(target, reviewedFieldState, changesDataExist) {
    if (reviewedFieldState === "false") {
      if (changesDataExist) {
        if (
          target.parentNode
            .querySelector('*[data-type="currentValue"]')
            .querySelector('input[name="selectedPlatform"]') !== null
        ) {
          return target.parentNode
            .querySelector('*[data-type="currentValue"]')
            .querySelector('input[name="selectedPlatform"]').value;
        }
      }
    } else {
      if (changesDataExist) {
        if (
          target.parentNode
            .querySelector('*[data-type="currentValue"]')
            .querySelector('input[name="selectedPlatform"]') !== null
        ) {
          return target.parentNode
            .querySelector('*[data-type="changesValue"]')
            .querySelector('input[name="selectedPlatform"]').value;
        }
      } else {
        if (
          target.parentNode
            .querySelector('*[data-type="currentValue"]')
            .querySelector('input[name="selectedPlatform"]') !== null
        ) {
          return target.parentNode
            .querySelector('*[data-type="currentValue"]')
            .querySelector('input[name="selectedPlatform"]').value;
        }
      }
    }
  }

  getReviewedTradingPlatformConditionDays(
    target,
    reviewedFieldState,
    changesDataExist
  ) {
    if (reviewedFieldState === "false") {
      if (changesDataExist) {
        if (
          target.parentNode
            .querySelector('*[data-type="currentValue"]')
            .querySelector('input[name="demoTradingPlatformConditionDays"]') !==
          null
        ) {
          return target.parentNode
            .querySelector('*[data-type="currentValue"]')
            .querySelector('input[name="demoTradingPlatformConditionDays"]')
            .value;
        }
      }
    } else {
      if (changesDataExist) {
        if (
          target.parentNode
            .querySelector('*[data-type="currentValue"]')
            ?.querySelector(
              'input[name="demoTradingPlatformConditionDays"]'
            ) !== null
        ) {
          return target.parentNode
            .querySelector('*[data-type="changesValue"]')
            ?.querySelector('input[name="demoTradingPlatformConditionDays"]')
            ?.value;
        }
      } else {
        if (
          target.parentNode
            .querySelector('*[data-type="currentValue"]')
            ?.querySelector(
              'input[name="demoTradingPlatformConditionDays"]'
            ) !== null
        ) {
          return target.parentNode
            .querySelector('*[data-type="currentValue"]')
            ?.querySelector('input[name="demoTradingPlatformConditionDays"]')
            ?.value;
        }
      }
    }
  }

  getReviewedDemoTradingPlatformCondition(
    target,
    reviewedFieldState,
    changesDataExist
  ) {
    if (reviewedFieldState === "false") {
      if (changesDataExist) {
        if (
          target.parentNode
            .querySelector('*[data-type="currentValue"]')
            .querySelector('input[name="selectedCondition"]') !== null
        ) {
          return target.parentNode
            .querySelector('*[data-type="currentValue"]')
            .querySelector('input[name="selectedCondition"]').value;
        }
      }
    } else {
      if (changesDataExist) {
        if (
          target.parentNode
            .querySelector('*[data-type="currentValue"]')
            .querySelector('input[name="selectedCondition"]') !== null
        ) {
          return target.parentNode
            .querySelector('*[data-type="changesValue"]')
            .querySelector('input[name="selectedCondition"]').value;
        }
      } else {
        if (
          target.parentNode
            .querySelector('*[data-type="currentValue"]')
            .querySelector('input[name="selectedCondition"]') !== null
        ) {
          return target.parentNode
            .querySelector('*[data-type="currentValue"]')
            .querySelector('input[name="selectedCondition"]').value;
        }
      }
    }
  }

  getReviewedTicker(target, reviewedFieldState) {
    if (target.closest(".translation_info__content")?.name) {
      if (reviewedFieldState === "false") {
        return (
          target.parentNode
            ?.querySelector('*[data-type="currentValue"]')
            ?.querySelector('[name="ticker"]')?.value || ""
        );
      } else {
        return (
          target.parentNode
            ?.querySelector('*[data-type="changesValue"]')
            ?.querySelector('[name="ticker"]')?.value || ""
        );
      }
    }
  }

  getReviewedLinkTicker(target, reviewedFieldState) {
    if (target.closest(".translation_info__content")?.name) {
      if (reviewedFieldState === "false") {
        return (
          target.parentNode
            ?.querySelector('*[data-type="currentValue"]')
            ?.querySelector('[name="linkTicker"]')?.value || ""
        );
      } else {
        return (
          target.parentNode
            ?.querySelector('*[data-type="changesValue"]')
            ?.querySelector('[name="linkTicker"]')?.value || ""
        );
      }
    }
  }

  getReviewedInstrument(target, reviewedFieldState) {
    alert(1);
    let type = reviewedFieldState === "true" ? "changesValue" : "currentValue";
    let name =
      reviewedFieldState === "false"
        ? target.parentNode.querySelector(".checkbox__label").innerText
        : "changes-" +
          target.parentNode.querySelector(".checkbox__label").innerText;
    // return {
    //   lowestLeverage:
    //     target.parentNode.querySelector(
    //       `[data-type=${type}][name='lowestLeverage']`
    //     )?.value || null,
    //   kindLeverage:
    //     Array.from(
    //       target.parentNode.querySelectorAll(`[name='${name}-kindLeverage']`)
    //     )?.find((radio) => radio.checked)?.value || "VARIABLE",
    //   typeLeverage:
    //     Array.from(
    //       target.parentNode.querySelectorAll(`[name='${name}-leverageType']`)
    //     )?.find((radio) => radio.checked)?.value || "LOT",
    //   instrumentId: target.parentNode.getAttribute("data-licenseid"),
    // };

    let obj = {
      kindLeverage:
        Array.from(
          target.parentNode.querySelectorAll(
            `[data-type='${type}'][name='${name}-kindLeverage']`
          )
        )?.find((radio) => radio.checked)?.value || "VARIABLE",
      instrumentId: target.parentNode.getAttribute("data-licenseid"),
    };
    if (obj.kindLeverage === "VARIABLE") {
      obj.accountTypeInstrumentPairs = [];
      document
        .querySelectorAll(".variable_leverage__position")
        .forEach((item) => {
          let pairInfo = {};
          item
            .querySelectorAll(`input[data-type='${type}']`)
            .forEach((input) => {
              if (input.name === "maxLeverage") {
                pairInfo.leverage = +input.value;
              } else {
                pairInfo[input.name] = +input.value;
              }
            });
          obj.accountTypeInstrumentPairs.push(pairInfo);
        });
    } else {
      obj.accountTypeInstrumentPairs = null;
      obj.leverage =
        target.parentNode.querySelector(
          `[data-type='${type}'][name='maxLeverage']`
        )?.value || null;
    }

    console.log(obj);
    return obj;
  }

  checkerState(checker) {
    return Array.from(checker.querySelector(".checker_set").elements).find(
      (input) => input.checked
    ).value;
  }

  parseCurrencyPairs() {
    let pairs = [];
    let inputs = [];
    if (Array.from(document.forms[0].currency_pair_data).length) {
      inputs = Array.from(document.forms[0].currency_pair_data);
    } else {
      inputs.push(document.forms[0].currency_pair_data);
    }
    inputs.forEach((item) => {
      pairs.push({ ...JSON.parse(item.value), id: item.id });
    });
    return pairs;
  }

  getRollbackType(checker) {
    if (checker?.closest(".translation_info__content")) {
      return checker.closest(".translation_info__content").name;
    } else if (checker.closest(".checkbox_select")) {
      return "licenseNumber";
    } else if (checker.closest(".demo_platform__container")) {
      return "demoPlatform";
    } else if (checker.closest(".options_wrap")) {
      return "brokerOption";
    } else if (checker.closest(".vipOptions_wrap")) {
      return "brokerVipOption";
    } else if (checker.closest(".currency_pairs_wrap")) {
      return "currencyPair";
    }
  }

  getRollbackName(checker) {
    if (
      checker.parentNode
        ?.querySelector('*[data-type="currentValue"]')
        .classList.contains("company_block__content")
    ) {
      return checker.parentNode
        ?.querySelector('*[data-type="currentValue"]')
        .querySelector("[name='name']").value;
    } else if (!checker.parentNode.classList.contains("select")) {
      return (
        checker.parentNode?.querySelector('*[data-type="currentValue"]')
          ?.name ||
        checker.parentNode
          ?.querySelector('*[data-type="currentValue"]')
          ?.getAttribute("name")
      );
    } else {
      return null;
    }
  }

  getRollbackValue(checker) {
    return (
      checker.parentNode.querySelector('*[data-type="currentValue"]').value ||
      checker.parentNode
        .querySelector('*[data-type="currentValue"]')
        .getAttribute("value")
    );
  }

  getRollbackId(checker) {
    if (
      checker.closest(".options_wrap") ||
      checker.closest(".vipOptions_wrap")
    ) {
      return parseInt(
        checker.parentNode.querySelector('*[data-type="currentValue"]').id
      );
    } else if (checker.closest(".checkbox_select")) {
      return parseInt(
        checker.parentNode
          .querySelector('*[data-type="currentValue"]')
          .getAttribute("data-id")
      );
    } else if (
      checker.closest(".translation_info__content")?.name === "history" ||
      checker.closest(".translation_info__content")?.name === "companyBlock" ||
      checker.closest(".demo_platform__container")
    ) {
      return (
        checker.parentNode
          .querySelector('*[data-type="currentValue"]')
          .getAttribute("id") ||
        checker.parentNode
          .querySelector('*[data-type="changesValue"]')
          .getAttribute("id")
      );
    } else {
      return null;
    }
  }

  getRollbackOptionId(checker) {
    if (
      checker.closest(".options_wrap") ||
      checker.closest(".vipOptions_wrap")
    ) {
      return parseInt(
        checker.parentNode.querySelector('*[data-type="currentValue"]').id
      );
    } else if (
      checker.closest(".translation_info__content")?.name === "history" ||
      checker.closest(".demo_platform__container")
    ) {
      return checker.parentNode.querySelector('*[data-type="currentValue"]').id;
    } else {
      return null;
    }
  }

  getRollbackLicenseId(checker) {
    if (checker.closest(".checkbox_select")) {
      return parseInt(
        checker.parentNode
          .querySelector('*[data-type="currentValue"]')
          .getAttribute("data-licenseId")
      );
    } else {
      return null;
    }
  }

  getRollbackDate(checker) {
    if (
      checker.parentNode
        .querySelector('*[data-type="currentValue"]')
        .querySelector('input[name="date"]') !== null
    ) {
      return checker.parentNode
        .querySelector('*[data-type="currentValue"]')
        .querySelector('input[name="date"]').value;
    } else {
      return "";
    }
  }

  getRollbackText(checker) {
    if (
      checker.parentNode
        .querySelector('*[data-type="currentValue"]')
        .querySelector('input[name="text"]') !== null
    ) {
      return checker.parentNode
        .querySelector('*[data-type="currentValue"]')
        .querySelector('input[name="text"]').value;
    } else {
      return "";
    }
  }

  getRollbackDemoTradingPlatformId(checker) {
    if (
      checker.parentNode
        .querySelector('*[data-type="currentValue"]')
        .querySelector('input[name="selectedPlatform"]') !== null
    ) {
      return parseInt(
        checker.parentNode
          .querySelector('*[data-type="currentValue"]')
          .querySelector('input[name="selectedPlatform"]').value ||
          checker.parentNode
            .querySelector('*[data-type="changesValue"]')
            .querySelector('input[name="selectedPlatform"]').value
      );
    } else {
      return "";
    }
  }

  getRollbackDemoTradingPlatformConditionDays(checker) {
    if (
      checker.parentNode
        .querySelector('*[data-type="currentValue"]')
        .querySelector('input[name="demoTradingPlatformConditionDays"]') !==
      null
    ) {
      return checker.parentNode
        .querySelector('*[data-type="currentValue"]')
        .querySelector('input[name="demoTradingPlatformConditionDays"]').value;
    } else {
      return "";
    }
  }

  getRollbackDemoTradingPlatformCondition(checker) {
    if (
      checker.parentNode
        .querySelector('*[data-type="currentValue"]')
        .querySelector('input[name="selectedCondition"]') !== null
    ) {
      return checker.parentNode
        .querySelector('*[data-type="currentValue"]')
        .querySelector('input[name="selectedCondition"]').value;
    } else {
      return "";
    }
  }

  getRollbackTicker(checker) {
    if (
      checker.parentNode
        .querySelector('*[data-type="currentValue"]')
        .querySelector('input[name="ticker"]') !== null
    ) {
      return checker.parentNode
        .querySelector('*[data-type="currentValue"]')
        .querySelector('input[name="ticker"]').value;
    } else {
      return "";
    }
  }

  getRollbackLinkTicker(checker) {
    if (
      checker.parentNode
        .querySelector('*[data-type="currentValue"]')
        .querySelector('input[name="linkTicker"]') !== null
    ) {
      return checker.parentNode
        .querySelector('*[data-type="currentValue"]')
        .querySelector('input[name="linkTicker"]').value;
    } else {
      return "";
    }
  }

  getRollbackInstruments(checker) {
    let name = checker.parentNode.querySelector(".checkbox__label").innerText;
    let obj = {
      kindLeverage:
        Array.from(
          checker.parentNode.querySelectorAll(`[name='${name}-kindLeverage']`)
        )?.find((radio) => radio.checked)?.value || "VARIABLE",
      instrumentId: checker.parentNode.getAttribute("data-licenseid"),
    };
    if (obj.kindLeverage === "VARIABLE") {
      obj.accountTypeInstrumentPairs = [];
      document
        .querySelectorAll(".variable_leverage__position")
        .forEach((item) => {
          let pairInfo = {};
          item
            .querySelectorAll("input[data-type='currentValue']")
            .forEach((input) => {
              if (input.name === "maxLeverage") {
                pairInfo.leverage = +input.value;
              } else {
                pairInfo[input.name] = +input.value;
              }
            });
          obj.accountTypeInstrumentPairs.push(pairInfo);
        });
    } else {
      obj.leverage =
        checker.parentNode.querySelector(
          `[data-type="currentValue"][name='maxLeverage']`
        )?.value || null;
    }
    obj.rollback = true;

    return obj;
  }

  getReviewedCurrencyPair(checker, reviewedFieldState) {
    return JSON.parse(
      checker.parentNode.querySelector(
        reviewedFieldState === "true" &&
          checker.parentNode.querySelector(
            "[name='changed_currency_pair_data']"
          )
          ? "[name='changed_currency_pair_data']"
          : "[name='currency_pair_data']"
      ).value
    );
  }

  getRollbackCurrencyPair(checker) {
    return JSON.parse(
      checker.parentNode.querySelector("[name='currency_pair_data']").value
    );
  }

  parseCustomFlags() {
    let parseCustomFlags = [];
    if (document.forms[0].brokerFlags) {
      Array.from(document.forms[0].brokerFlags).forEach((item) => {
        parseCustomFlags.push({
          languageSlug: item.elements.languageSlug.value,
          flags: item.elements.flags.value,
          id: item.elements.id.value,
        });
      });
    }
    return parseCustomFlags;
  }
}

export default ParseServices;
